import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-speech-bubble',
  templateUrl: './speech-bubble.component.html',
  styleUrls: ['./speech-bubble.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpeechBubbleComponent {
  public arrowPosition = input<'bottom' | 'none'>('bottom');
  public isInteractive = input<boolean>(true);
}
