import {
  Directive,
  ElementRef,
  Renderer2,
  HostListener,
  ViewContainerRef,
  TemplateRef,
  OnDestroy,
  input,
  output,
  inject,
} from '@angular/core';

/**
 * Директива для отображения контекстного меню при выделении текста
 */
@Directive({
  selector: '[appTextSelection]',
})
export class TextSelectionDirective implements OnDestroy {
  private readonly el = inject(ElementRef);
  private readonly renderer = inject(Renderer2);
  private readonly viewContainerRef = inject(ViewContainerRef);

  // Шаблон меню
  appTextSelectionMenu = input<TemplateRef<any> | null>();
  appTextSelectionEnabled = input<boolean>(true);
  // Меню позиционируется по координатам мыши, false – по выделенному тексту
  appTextSelectionUseMousePos = input<boolean>(false);
  lastSelectedText = output<string>();

  private isMouseDown = false;
  private menuNativeElement: HTMLElement = null;

  private documentMousedownListener: () => void;
  private documentMouseupListener: () => void;

  private getSelection(): Selection | null {
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
      return selection;
    }
    return null;
  }

  @HostListener('mousedown')
  onMouseDown() {
    this.isMouseDown = true;
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    if (!this.appTextSelectionEnabled() || !this.isMouseDown) {
      return;
    }
    this.isMouseDown = false;

    setTimeout(() => {
      const selection = this.getSelection();
      if (selection) {
        this.lastSelectedText.emit(selection.toString());

        if (this.appTextSelectionUseMousePos()) {
          // Если включен режим отображения меню по позиции мыши,
          // создаём фиктивный DOMRect, используя координаты события
          const x = event.clientX + 5;
          const y = event.clientY + 5;
          const fakeRange: DOMRect = {
            top: y,
            bottom: y,
            left: x,
            right: x,
            width: 0,
            height: 0,
            x,
            y,
            toJSON: () => ({}),
          };
          this.showMenu(fakeRange);
        } else {
          // Стандартный режим: позиционирование меню относительно выделенного текста
          const range = selection.getRangeAt(0).getBoundingClientRect();
          this.showMenu(range);
        }

        // Добавляем глобальный обработчик кликов для скрытия меню
        this.addDocumentClickListener();
      }
    }, 50);
  }

  addDocumentClickListener() {
    // Убираем старый обработчик, если он был
    if (this.documentMousedownListener) {
      this.documentMousedownListener();
    }
    if (this.documentMouseupListener) {
      this.documentMouseupListener();
    }

    const hideMenu = () => {
      this.viewContainerRef.clear();
      this.removeDocumentClickListener();
    };

    // Добавляем новый обработчик кликов по всему документу
    this.documentMousedownListener = this.renderer.listen('document', 'mousedown', event => {
      // Если кликнули внутри меню, не скрываем его
      if (this.menuNativeElement.contains(event.target as HTMLElement)) {
        return;
      }

      hideMenu();
    });

    this.documentMouseupListener = this.renderer.listen('document', 'mouseup', () => {
      setTimeout(hideMenu, 50);
    });
  }

  private getScrollableParent(element: HTMLElement): HTMLElement | null {
    let parent: HTMLElement | null = element;

    while (parent) {
      const overflowY = window.getComputedStyle(parent).overflowY;
      const overflowX = window.getComputedStyle(parent).overflowX;

      const isScrollable =
        overflowY === 'scroll' || overflowY === 'auto' || overflowX === 'scroll' || overflowX === 'auto';

      if (isScrollable) {
        return parent; // Возвращаем первый прокручиваемый родитель
      }

      parent = parent.parentElement;
    }

    return null;
  }

  showMenu(range: DOMRect) {
    // Очищаем предыдущее содержимое
    this.viewContainerRef.clear();

    // Вставляем меню во временный контейнер, чтобы измерить его размеры
    let embeddedView = this.viewContainerRef.createEmbeddedView(this.appTextSelectionMenu());
    let menuElement = embeddedView.rootNodes[0];

    this.renderer.setStyle(menuElement, 'position', 'absolute');
    this.renderer.setStyle(menuElement, 'visibility', 'hidden');
    this.renderer.appendChild(document.body, menuElement);

    // Измеряем размеры меню
    const menuWidth = menuElement.offsetWidth;
    const menuHeight =
      menuElement.offsetHeight +
      // Добавим высоту ушка, так как оно absolute не учитывается в offsetHeight.
      // В режиме с мышкой обычно у меню нет ушка,
      // поэтому добавляем его только в режиме по выделенному тексту.
      // Потом можно будет вынести в отдельный параметр, если понадобится
      (this.appTextSelectionUseMousePos() ? 0 : 8);

    // Удаляем временно добавленный элемент из DOM
    this.renderer.removeChild(document.body, menuElement);

    // Используем родительский элемент (this.el.nativeElement) для всех вычислений
    const parentElement = this.el.nativeElement as HTMLElement;
    const parentRect = parentElement.getBoundingClientRect();

    let topPosition: number;
    let leftPosition: number;

    if (this.appTextSelectionUseMousePos()) {
      // Режим отображения по позиции мыши:
      // позиция берется напрямую из фиктивного range, созданного по координатам события
      topPosition = range.top - parentRect.top + 5;
      leftPosition = range.left - parentRect.left + 5;
    } else {
      // Стандартный режим: меню появляется над выделенным текстом и центрируется по горизонтали
      topPosition = range.top - parentRect.top - menuHeight;
      leftPosition = range.left - parentRect.left + range.width / 2 - menuWidth / 2;
    }

    // Корректируем позицию меню, чтобы оно не выходило за пределы родительского элемента (this.el)
    const parentWidth = parentElement.clientWidth;
    const parentHeight = parentElement.clientHeight;

    if (leftPosition < 0) {
      leftPosition = 0;
    }
    if (leftPosition + menuWidth > parentWidth) {
      leftPosition = parentWidth - menuWidth;
    }
    if (topPosition < 0) {
      topPosition = 0;
    }
    if (topPosition + menuHeight > parentHeight) {
      topPosition = parentHeight - menuHeight;
    }

    // Вставляем меню в DOM с рассчитанными координатами
    embeddedView = this.viewContainerRef.createEmbeddedView(this.appTextSelectionMenu());
    menuElement = embeddedView.rootNodes[0];

    // Устанавливаем корректные стили
    this.renderer.setStyle(menuElement, 'position', 'absolute');
    this.renderer.setStyle(menuElement, 'z-index', '1');
    this.renderer.setStyle(menuElement, 'top', `${topPosition}px`);
    this.renderer.setStyle(menuElement, 'left', `${leftPosition}px`);
    this.menuNativeElement = menuElement;
  }

  removeDocumentClickListener() {
    if (this.documentMousedownListener) {
      this.documentMousedownListener();
      this.documentMousedownListener = null;
    }

    if (this.documentMouseupListener) {
      this.documentMouseupListener();
      this.documentMouseupListener = null;
    }
  }

  ngOnDestroy() {
    this.removeDocumentClickListener();
  }
}
