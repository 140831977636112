import { Injectable } from '@angular/core';
import {
  ChatContactsListItem,
  CrmCardViewItem,
  DealChangedEventDetail,
  WhatsappMessage,
  WhatsappNewMessage,
  ChatRecommendation,
} from '@api-clients/crm-api-client/dist';
import { ChatRecommendationMessage } from '@api-clients/crm-api-client/models/chat-recommendation-message';
import { Store } from '@ngrx/store';
import { debounceTime, filter, map, Observable, take, withLatestFrom } from 'rxjs';
import { IChatContactChangedEvent, INewMessages, IStatuses } from '../../models/whatsapp';
import { WorkerStateService } from '../../core/services';
import { AmplitudeTrackService } from '../../core/services/amplitude/amplitude-track.service';
import {
  ChatListExclusiveFilterKey,
  ChatListInclusiveFilterKeys,
  ChatListItemContactChangedProps,
  ChatListSortType,
  ChatListStageFilterKeys,
  ChatListTouchTodayFilterKeys,
  ChatsMenuCounter,
  ChatsWithDealsResult,
} from '../../modules/chats/chats';
import { DealsFacade } from '../deals/deals.facade';
import { UpdateUnreadChatsCount } from '../whatsapp-contacts/whatsapp-contacts.actions';
import * as ChatsActions from './chats.actions';
import { ChatsState } from './chats.reducer';
import {
  selectAllChatContacts,
  selectChatContactsError,
  selectChatContactsLoading,
  selectChatContactsLoadingMore,
  selectChatRecommendation,
  selectCurrentChatContact,
  selectCurrentChatContactCrmCard,
  selectCurrentChatContactCrmCardLoading,
  selectCurrentChatContactInvisibleRead,
  selectCurrentChatContactNextTaskLoading,
  selectCurrentExclusiveFilter,
  selectCurrentInclusiveFilters,
  selectCurrentSort,
  selectCurrentStageFilter,
  selectCurrentTouchTodayFilter,
  selectExclusiveFilteredChatsWithDeals,
  selectExclusiveFilteredChatsWithDealsCountBy,
  selectHasAnyInclusiveFiltersEnabled,
  selectHasForgotten,
  selectHasGroups,
  selectHasUnreadChats,
  selectHasUnreadGroups,
  selectInclusiveFilterCounters,
  selectIsLeftToTouchTodayFilterEnabled,
  selectIsSearching,
  selectIsTouchedTodayFilterEnabled,
  selectIsTouchTodayFilterEnabled,
  selectReadAllChatsLoading,
  selectSearchResults,
  selectStageFilteredChatsWithDealsCountBy,
  selectTouchTodayAllCount,
  selectTouchTodayFilteredChatContactsCountBy,
} from './chats.selectors';
import { CardLastSearchRequestChanged } from '../deals/deals.interface';

@Injectable({
  providedIn: 'root',
})
export class ChatsFacade {
  chatContactsAll$: Observable<ChatContactsListItem[]> = this.store.select(selectAllChatContacts);
  chatsWithDealsExclusiveFiltered$: Observable<ChatsWithDealsResult> = this.store.select(
    selectExclusiveFilteredChatsWithDeals,
  );
  chatContactsExclusiveFilteredCountBy$ = (filter: ChatListExclusiveFilterKey): Observable<number> =>
    this.store.select(selectExclusiveFilteredChatsWithDealsCountBy(filter));
  chatContactsStageFilteredCountBy$ = (
    stage: ChatListStageFilterKeys,
    from?: string,
  ): Observable<ChatsMenuCounter> => this.store.select(selectStageFilteredChatsWithDealsCountBy(stage, from));
  readAllChatsLoading$: Observable<boolean> = this.store.select(selectReadAllChatsLoading);
  loading$: Observable<boolean> = this.store.select(selectChatContactsLoading);
  loadingMore$: Observable<boolean> = this.store.select(selectChatContactsLoadingMore);
  error$: Observable<string> = this.store.select(selectChatContactsError);
  currentChatContact$: Observable<ChatContactsListItem> = this.store.select(selectCurrentChatContact);
  currentChatContactInvisibleRead$: Observable<boolean> = this.store.select(
    selectCurrentChatContactInvisibleRead,
  );
  currentChatContactCrmCard$: Observable<CrmCardViewItem> = this.store.select(
    selectCurrentChatContactCrmCard,
  );
  currentChatContactCrmCardLoading$: Observable<boolean> = this.store.select(
    selectCurrentChatContactCrmCardLoading,
  );
  currentChatContactNextTaskLoading$: Observable<boolean> = this.store.select(
    selectCurrentChatContactNextTaskLoading,
  );
  chatRecommendation$: Observable<ChatRecommendation> = this.store.select(selectChatRecommendation);
  searchResults$: Observable<ChatContactsListItem[]> = this.store.select(selectSearchResults);
  isSearching$: Observable<boolean> = this.store.select(selectIsSearching);
  currentInclusiveFilter$: Observable<ChatListInclusiveFilterKeys[]> = this.store.select(
    selectCurrentInclusiveFilters,
  );
  currentExclusiveFilter$: Observable<ChatListExclusiveFilterKey> = this.store.select(
    selectCurrentExclusiveFilter,
  );
  hasAnyInclusiveFiltersEnabled$: Observable<boolean> = this.store.select(
    selectHasAnyInclusiveFiltersEnabled,
  );
  inclusiveFilterCounters$: Observable<Record<ChatListInclusiveFilterKeys, number>> = this.store.select(
    selectInclusiveFilterCounters,
  );
  hasForgotten$: Observable<boolean> = this.store.select(selectHasForgotten);
  hasGroups$: Observable<boolean> = this.store.select(selectHasGroups);
  hasUnreadChats$: Observable<boolean> = this.store.select(selectHasUnreadChats);
  hasUnreadGroups$: Observable<boolean> = this.store.select(selectHasUnreadGroups);

  currentStageFilter$: Observable<ChatListStageFilterKeys> = this.store.select(selectCurrentStageFilter);

  currentSort$: Observable<ChatListSortType> = this.store.select(selectCurrentSort);

  isTouchTodayFilterEnabled$: Observable<boolean> = this.store.select(selectIsTouchTodayFilterEnabled);
  isLeftToTouchTodayFilterEnabled$: Observable<boolean> = this.store.select(
    selectIsLeftToTouchTodayFilterEnabled,
  );
  isTouchedTodayFilterEnabled$: Observable<boolean> = this.store.select(selectIsTouchedTodayFilterEnabled);
  currentTouchTodayFilter$: Observable<ChatListTouchTodayFilterKeys> = this.store.select(
    selectCurrentTouchTodayFilter,
  );
  touchTodayAllCount$: Observable<number> = this.store.select(selectTouchTodayAllCount);
  touchedTodayCount$: Observable<number> = this.store.select(
    selectTouchTodayFilteredChatContactsCountBy(ChatListTouchTodayFilterKeys.TOUCHED_TODAY),
  );
  leftToTouchTodayCount$: Observable<number> = this.store.select(
    selectTouchTodayFilteredChatContactsCountBy(ChatListTouchTodayFilterKeys.LEFT_TO_TOUCH),
  );

  public readonly workInProgressStages = [
    ChatListStageFilterKeys.IN_PROGRESS,
    ChatListStageFilterKeys.FIRST_DAY,
    ChatListStageFilterKeys.SECOND_DAY,
    ChatListStageFilterKeys.THIRD_DAY,
    ChatListStageFilterKeys.FOURTH_TO_SEVENTH_DAYS,
    ChatListStageFilterKeys.MORE_THAN_SEVEN_DAYS,
  ];

  private lastMessageIsFromMe = false;

  constructor(
    private store: Store<ChatsState>,
    private workerStateService: WorkerStateService,
    private dealsFacade: DealsFacade,
    private amplitudeTrackService: AmplitudeTrackService,
  ) {
    // Пока только для Бекжанова Аида megaaida12 (739)
    // this.workerStateService.currentWorker$
    //   .pipe(
    //     filter(worker => worker?.employeeId === 739),
    //     switchMap(() => this.dealsFacade.dealsList$),
    //     // Ожидаем, пока список сделок не перестанет изменяться в течение 1 секунды.
    //     // Т.к. там сортировка идет отдельно через эффекты и нельзя использовать первый же результат
    //     // списка сделок и генерировать на основе него zenmodeList
    //     debounceTime(1000),
    //   )
    //   .subscribe(() => {
    //     При обновлении сделок надо посмотреть не потеряла ли актуальность существующая рекомендация
    //     this.store.dispatch(ChatsActions.checkChatRecommendation());
    //   });
  }

  setCurrentEmployee(employeeId: number) {
    this.store.dispatch(ChatsActions.setCurrentEmployee({ employeeId }));
  }

  loadChatContacts() {
    this.store.dispatch(ChatsActions.loadChatContacts());
    // Пока только для Бекжанова Аида megaaida12 (739)
    /*if (this.workerStateService.employeeId === 739) {
      this.store.dispatch(ChatsActions.loadChatRecommendation());
    }*/
  }

  loadUnreadChats() {
    this.store.dispatch(ChatsActions.loadUnreadChats());
  }

  loadGroupChats() {
    this.store.dispatch(ChatsActions.loadGroupChats());
  }

  loadChatsWithActiveDeals() {
    this.store.dispatch(ChatsActions.loadChatsWithActiveDeal());
  }

  searchContacts(searchQuery: string): void {
    this.store.dispatch(ChatsActions.searchContacts({ searchQuery }));
  }

  clearSearch(): void {
    this.store.dispatch(ChatsActions.clearSearchResults());
  }

  chooseChatContact(chatContact: ChatContactsListItem) {
    this.store.dispatch(ChatsActions.chooseChatContact({ chatContact }));
  }

  chooseChatContactAndEnableInvisibleMode(chatContact: ChatContactsListItem) {
    this.enableInvisibleRead();
    this.chooseChatContact(chatContact);
  }

  chooseChatContactAndDisableInvisibleRead(chatContact: ChatContactsListItem) {
    this.disableInvisibleRead();
    this.store.dispatch(ChatsActions.chooseChatContact({ chatContact }));
  }

  findAndChooseOrLoadNewChatContact(chatContactId: string) {
    this.chatContactsAll$
      .pipe(
        take(1),
        // только после 1ой загрузки
        filter(chatContacts => chatContacts.length > 0),
        // Проверяем есть контакт из сообщения в нашем текущем списке
        map(chatContacts => chatContacts.find(contact => contact.contact.id === chatContactId)),
      )
      .subscribe(existingContact => {
        if (existingContact) {
          // Контакт загружен
          this.chooseChatContact(existingContact);
        } else {
          // В противном случае загружаем контакт и добавляем в список
          this.loadNewContact(chatContactId);
        }
      });
  }

  updateChatContact(chatContact: ChatContactsListItem) {
    this.store.dispatch(ChatsActions.updateChatContact({ chatContact }));
  }

  loadDealViewForChat(crmCardId: number, from?: string) {
    this.store.dispatch(ChatsActions.loadCrmCardViewItemForChat({ crmCardId, from }));
  }

  setCrmCardViewItemForChat(crmCardViewItem: CrmCardViewItem) {
    this.store.dispatch(ChatsActions.setCrmCardViewItemForChat({ crmCardViewItem }));
  }

  updateCrmCardViewItemForChat(crmCardId: number, crmCardViewItem: Partial<CrmCardViewItem>) {
    this.store.dispatch(ChatsActions.updateCrmCardViewItemForChat({ crmCardId, crmCardViewItem }));
  }

  loadDealNextTask(crmCardId: number) {
    this.store.dispatch(ChatsActions.loadDealViewNextTask({ crmCardId }));
  }

  resetCurrentChatContact() {
    this.store.dispatch(ChatsActions.resetCurrentChatContact());
  }

  resetCurrentChatState() {
    this.store.dispatch(ChatsActions.resetCurrentChatState());
  }

  readChat(contactId: string, fromPlace: string) {
    this.store.dispatch(ChatsActions.readChat({ contactId, fromPlace }));
    // if (!this.lastMessageIsFromMe) {
    // }
  }

  readAllChats() {
    this.store.dispatch(ChatsActions.readAllChats());
  }

  enableInvisibleRead() {
    this.store.dispatch(ChatsActions.enableInvisibleRead());
  }

  disableInvisibleRead() {
    this.store.dispatch(ChatsActions.disableInvisibleRead());
  }

  updateChatContactsList(newMessages: INewMessages) {
    newMessages.messages.forEach((newMessage: WhatsappNewMessage) => {
      const contactId = newMessage.contact.id;
      const message = newMessage.message;
      this.checkAndUpdateContactByMessage(contactId, newMessage.message);

      this.lastMessageIsFromMe = message.isFromMe;

      this.currentChatContact$
        .pipe(
          take(1),
          filter(currentChatContact => currentChatContact?.contact.id === contactId),
          withLatestFrom(this.currentChatContactInvisibleRead$),
          debounceTime(1000),
        )
        .subscribe(([, invisibleRead]) => {
          if (message.isFromMe && invisibleRead) {
            this.readChat(contactId, 'hermes_after_outgoing_message');
            this.disableInvisibleRead();
          }

          if (!message.isFromMe && !invisibleRead) {
            this.readChat(contactId, 'hermes_new_message_received');
          }
        });
    });
  }

  updateUnreadChatsCounter(newUnreadChatsCount: number) {
    this.store.dispatch(new UpdateUnreadChatsCount({ unreadChatCount: newUnreadChatsCount }));
  }

  refreshStatusOfMessages(statuses: IStatuses) {
    this.store.dispatch(ChatsActions.refreshMessageStatuses({ statuses }));
  }

  markRecommendationMessageAsDeleted(message: ChatRecommendationMessage): void {
    this.store.dispatch(ChatsActions.markRecommendationMessageAsDeleted(message));
  }

  markRecommendationMessageAsSent(message: ChatRecommendationMessage, changedText: string = null): void {
    this.store.dispatch(ChatsActions.markRecommendationMessageAsSent({ message, changedText }));
  }

  setStageChatListFilter(stage: ChatListStageFilterKeys): void {
    this.store.dispatch(ChatsActions.setStageFilter({ stage }));
  }

  setExclusiveChatListFilter(filter: ChatListExclusiveFilterKey): void {
    this.store.dispatch(ChatsActions.setExclusiveFilter({ filter }));
    this.resetLastReadChatContactId();
  }

  resetLastReadChatContactId(): void {
    this.store.dispatch(ChatsActions.resetLastReadChatContactId());
  }

  setTempInclusiveChatListFiltersForCounters(filters: ChatListInclusiveFilterKeys[]): void {
    this.store.dispatch(ChatsActions.setTempInclusiveFiltersForCounters({ filters }));
  }

  resetTempInclusiveChatListFiltersForCounters(): void {
    this.store.dispatch(ChatsActions.resetTempInclusiveFiltersForCounters());
  }

  setInclusiveChatListFilters(filters: ChatListInclusiveFilterKeys[]): void {
    this.store.dispatch(ChatsActions.setInclusiveFilters({ filters }));
  }

  resetInclusiveChatListFilters(): void {
    this.store.dispatch(ChatsActions.resetInclusiveFilters());
  }

  resetStageChatListFilter(): void {
    this.store.dispatch(ChatsActions.resetStageFilter());
  }

  setSort(sort: ChatListSortType): void {
    this.store.dispatch(ChatsActions.setSort({ sort }));
  }

  resetSort(): void {
    this.store.dispatch(ChatsActions.resetSort());
  }

  setTouchTodayFilter(key: ChatListTouchTodayFilterKeys) {
    this.store.dispatch(ChatsActions.setTouchTodayFilter({ key }));
  }

  resetTouchTodayFilter(): void {
    this.store.dispatch(ChatsActions.resetTouchTodayFilter());
  }

  cardLastSearchRequestChanged({ detail }: CardLastSearchRequestChanged) {
    this.updateCrmCardViewItemForChat(detail.cardId, { lastSearchRequest: detail.lastSearchRequest });
  }

  dealChanged(payload: DealChangedEventDetail) {
    if (payload.isNew === null) {
      // Нет изменений, обновление не требуется
      return;
    }

    // ВАЖНО! При изменении status/stage/nextTask и проще просто перезагрузить новую версию контакта
    // Так как требуется определение категории, где чат окажется!
    this.store.dispatch(
      ChatsActions.updateChatContactDealInfo({
        dealId: payload.dealId,
        changes: {
          hasUnreadChanges: payload.isNew,
        },
      }),
    );
  }

  contactInfoChanged({ detail }: IChatContactChangedEvent) {
    const changes: ChatListItemContactChangedProps = {};
    if (detail.displayName) {
      changes.displayName = detail.displayName;
    }
    if (detail.avatarUri) {
      changes.avatarUri = detail.avatarUri;
    }

    if (Object.keys(changes).length === 0) {
      // no valid changes
      return;
    }

    this.store.dispatch(
      ChatsActions.updateChatContactBaseContactInfo({
        contactId: detail.contactId,
        changes,
      }),
    );
  }

  private checkAndUpdateContactByMessage(contactId: string, message: WhatsappMessage) {
    this.chatContactsAll$
      .pipe(
        take(1),
        // Проверяем есть контакт из сообщения в нашем текущем списке
        map(chatContacts => chatContacts.find(contact => contact.contact.id === contactId)),
      )
      .subscribe(existingContact => {
        if (existingContact) {
          // Если контакт есть, то обновляем у него last message и сортируем список
          this.updateExistingContact(existingContact, message);
        } else {
          // В противном случае загружаем контакт и добавляем в список
          this.loadNewContact(contactId);
        }
      });
  }

  private updateExistingContact(existingContact: ChatContactsListItem, message: WhatsappMessage) {
    const { unreadMessageCount } = existingContact.contact;
    const updatedUnreadMessageCount = message.isFromMe ? unreadMessageCount : unreadMessageCount + 1;

    this.store.dispatch(
      ChatsActions.updateChatContact({
        chatContact: {
          ...existingContact,
          contact: {
            ...existingContact.contact,
            unreadMessageCount: updatedUnreadMessageCount,
          },
          lastMessage: message,
        },
      }),
    );
  }

  loadChatContact(contactId: string) {
    this.store.dispatch(ChatsActions.loadChatContact({ contactId }));
  }

  private loadNewContact(contactId: string) {
    this.store.dispatch(ChatsActions.loadChatContact({ contactId }));
  }
}
