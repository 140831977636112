<div
  class="speech-bubble"
  [class.is-interactive]="isInteractive()"
  [class.arrow-none]="arrowPosition() === 'none'"
>
  <ng-content></ng-content>
  @if (arrowPosition() !== 'none') {
    <div class="speech-bubble-arrow"></div>
  }
</div>
