import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { HotelManagerComment } from '@api-clients/api-client';
import { TourContent } from '@api-clients/api-client/models/tour-content';
import { Observable, of } from 'rxjs';
import { PluralizePipe } from '../../../../../helpers/pipes/plural/pluralize.pipe';
import { TextSelectionDirective } from '../../../../../shared/directives/text-selection.directive';
import { BrxAlertLabelComponent } from '../../../../../ui-components/brx/alert-label/brx-alert-label.component';
import { BrxIconComponent } from '../../../../../ui-components/brx/icon/brx-icon.component';
import { BrxScrollableTargetDirective } from '../../../../../ui-components/brx/scrollable-wrapper/brx-scrollable-target.directive';
import { SpeechBubbleComponent } from '../../../../../ui-components/speech-bubble/speech-bubble.component';
import {
  ContentCreatorItemComponent,
  ContentCreatorItemMessage,
} from '../../../interfaces/content-creator.interface';

@Component({
  selector: 'app-tour-content-manager-comments',
  imports: [
    PluralizePipe,
    SpeechBubbleComponent,
    TextSelectionDirective,
    BrxAlertLabelComponent,
    DatePipe,
    BrxScrollableTargetDirective,
    BrxIconComponent,
  ],
  templateUrl: './manager-comments.component.html',
  styleUrls: ['./manager-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManagerCommentsComponent implements ContentCreatorItemComponent, OnInit {
  @Input() tourContent: TourContent;
  @Input() tourId: string;
  @Input() appTextSelectionEnabled = true;
  @Output() insertSingleMessageText = new EventEmitter<string>();
  lastSelectedText = signal<string>('');

  public comments: HotelManagerComment[];

  public errorMessage = signal<string>('');

  ngOnInit(): void {
    this.comments = this.tourContent?.hotel?.managerComments;
    if (!this.comments || !this.comments.length) {
      this.errorMessage.set('Комментарии менеджеров не найдены');
    }
  }

  addTextToMessage(text: string) {
    this.insertSingleMessageText.emit(`\n${text}`);
  }

  getMessagesForSend(): Observable<ContentCreatorItemMessage[]> {
    return of([] as ContentCreatorItemMessage[]);
  }
}
