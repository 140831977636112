@if (errorMessage()) {
  <brx-alert-label type="danger">
    {{ errorMessage() }}
  </brx-alert-label>
} @else {
  <div class="filter-and-sorting">
    <div class="tags-filter" (click)="toggleTagsFilter($event)">
      Поиск по потребностям
      @if (selectedTagIds().length) {
        ({{ selectedTagIds().length }})
      }
    </div>
    @if (isTagsFilterOpened()) {
      <app-favorite-hotels-map-filter-tags-copy
        #tagsFilter
        [hotelIds]="[tourContent.tourMessageData.hotel.id]"
        (closed)="selectTags($event)"
        [selectedIds]="getSelectedTagIds()"
      />
    }

    <select class="select" [(ngModel)]="sorting" (ngModelChange)="changeSorting($event)">
      <option value="newFirst">Сначала новые</option>
      <option value="oldFirst">Сначала старые</option>
    </select>
  </div>

  <div class="count-info">
    <div>Всего:</div>
    <div class="count">{{ reviews().length | pluralize: 'отзыв,отзыва,отзывов' }}</div>
  </div>

  <div brxScrollableTarget>
    <div
      class="review-list"
      appTextSelection
      [appTextSelectionEnabled]="appTextSelectionEnabled"
      [appTextSelectionMenu]="menuTemplate"
      [appTextSelectionUseMousePos]="true"
      (lastSelectedText)="lastSelectedText.set($event)"
    >
      @for (review of reviews(); track review) {
        <div class="review">
          <div class="review-header">
            <div class="review-author">{{ review.author }}</div>
            <div class="review-date">{{ review.createdAt | date: 'dd.MM.yyyy' }}</div>
          </div>
          @if (review.likeComment) {
            <div class="review-text like">
              <div class="icon" (click)="addTextToMessage(review.likeComment)"></div>
              <div [innerHTML]="review.likeComment | safeHtml"></div>
            </div>
          }
          @if (review.notLikeComment) {
            <div class="review-text dislike">
              <div class="icon" (click)="addTextToMessage(review.notLikeComment)"></div>
              <div [innerHTML]="review.notLikeComment | safeHtml"></div>
            </div>
          }
        </div>
      }
    </div>
  </div>

  <ng-template #menuTemplate>
    <app-speech-bubble (click)="addTextToMessage(lastSelectedText())" arrowPosition="none">
      <brx-icon name="@brx.plus-bold" [size]="10" /> Добавить в сообщение
    </app-speech-bubble>
  </ng-template>
}

@if (isLoading()) {
  <brx-loader-fullscreen />
}
